/*
 * @Author: 张圳
 * @Description:
 * @Date: 2023-05-29 18:34:13
 * @LastEditTime: 2024-01-03 13:43:20
 * @FilePath: \business\src\main.ts
 */
import { createApp, Directive } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElIcons from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css'
// import dir from './utils/disabled'
import './menu' // 判断登陆人是否有权限
// import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap'
// import '@vuemap/vue-amap/dist/style.css'
import './assets/iconfont/iconfont.css'
import * as directives from '@/directives'
const app = createApp(App)
for (const name in ElIcons) {
  app.component(name, (ElIcons as never)[name])
}
// console.log(directives, 'directives') // 打印发现是导出的自定义指令名，permission
Object.keys(directives).forEach(key => { // Object.keys() 返回一个数组，值是所有可遍历属性的key名
  app.directive(key, (directives as { [key: string]: Directive })[key]) // key是自定义指令名字；后面应该是自定义指令的值，值类型是string
})
// app.directive('preventReClick', dir.preventReClick)
// initAMapApiLoader({
//   key: 'b70d860e437c5ee741bca088c14efc07',
//   plugins: [
//     'AMap.Geolocation', // 定位空间，用来获取和展示用户主机所在的经纬度位置
//     'AMap.Autocomplete', // 输入提示插件
//     'AMap.PlaceSearch', // POI搜索插件
//     'AMap.Scale', // 右下角缩略图插件，比例尺
//     'AMap.OverView', // 地图鹰眼插件
//     'AMap.ToolBar', // 地图工具条
//     'AMap.MapType', // 类别切换空间，实现默认图层与卫星图，实施交通层之间切换的控制
//     'AMap.PolyEditor', // 编辑 折线多边形
//     'AMap.CircleEditor',
//     'AMap.Geocoder' // 地图编码
//   ]
// })
// app.use(store).use(router).use(ElementPlus, { locale: zhCn }).use(VueAMap).mount('#app')
app.use(store).use(router).use(ElementPlus, { locale: zhCn }).mount('#app')
