import { get, post } from './http'

const API: any = {
  getUserInfo: '/permission/user/currentUser', // 获取用户信息
  updateUserInfo: '/design/member/update', // 获取用户信息

  getMenus: '/permission/menu/menus', // 获取所有菜单
  getPermission: '/permission/menu/chlidren', // 获取所有按钮
  getRoles: '/permission/menu/roles'// 获取数据权限
}

export const getUserInfoApi = () => get(API.getUserInfo)
export const updateUserInfoApi = (data: any) => post(API.updateUserInfo, data, '')

export const getMenusApi = (data: any) => get(API.getMenus, data)
export const getPermissionApi = (data: any) => get(API.getPermission, data)
export const getRolesApi = (data: any) => get(API.getRoles, data)
