import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a215d88"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "title",
  ref: "titleImg"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    class: "el-menu-vertical",
    "default-active": _ctx.currentMenu,
    collapse: _ctx.isCollapse,
    "unique-opened": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_image, {
          class: "title-logo",
          src: _ctx.state.logoUrl
        }, null, 8, ["src"]),
        _createElementVNode("h1", {
          class: _normalizeClass(["title-info", { active: _ctx.isCollapse }])
        }, _toDisplayString(_ctx.state.title), 3)
      ], 512),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states.routersList, (v) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: v.name
        }, [
          (v.children && v.children.length > 0 && !v.hideChildrenInMenu)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_el_sub_menu, {
                  index: v.name
                }, {
                  title: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(v.meta.icon)))
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("span", null, _toDisplayString(v.meta.title), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.children, (c) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: c.name
                      }, [
                        (!c.hidden)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 0,
                              index: c.name,
                              onClick: ($event: any) => (_ctx.toView(c.path))
                            }, {
                              title: _withCtx(() => [
                                _createTextVNode(_toDisplayString(c.meta.title), 1)
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(c.meta.icon)))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["index", "onClick"]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["index"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (!v.hidden)
                  ? (_openBlock(), _createBlock(_component_el_menu_item, {
                      key: 0,
                      index: v.name,
                      onClick: ($event: any) => (_ctx.toView(v.path,v.redirect))
                    }, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(v.meta.title), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(v.meta.icon)))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["index", "onClick"]))
                  : _createCommentVNode("", true)
              ]))
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["default-active", "collapse"]))
}