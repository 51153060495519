
import { defineComponent, computed, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup () {
    const store = useStore()
    const isCollapse = computed(() => store.getters.getIsCollapse)
    const currentMenu = computed(() => store.getters.getCurrentMenu)

    const router = useRouter()
    const toView = (url: string, redirect: string) => {
      if (redirect) {
        router.push({ path: redirect })
      } else {
        router.push({ path: url })
      }
    }
    onMounted(() => {
      console.log(store.getters.getCurrentMenu, '菜单')
    })
    const state = {
      title: '客资管理',
      logoUrl: require('@/assets/logo.png')
    }
    const titleImg = ref()
    watch(() => isCollapse, (newVal, oldVal) => {
      console.log(newVal, oldVal)
      // if(){}
    })
    const states: any = reactive({
      routersList: computed(() => store.getters.getRouters)
    })
    return {
      state,
      states,
      isCollapse,
      currentMenu,
      titleImg,
      toView
    }
  }
})
