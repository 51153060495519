import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df23a3e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-left" }
const _hoisted_2 = { class: "top-right" }
const _hoisted_3 = { class: "header-img" }
const _hoisted_4 = { class: "el-dropdown-link" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_School = _resolveComponent("School")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "domTopRef",
      class: _normalizeClass(["main-top", { active: _ctx.isCollapse }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_icon, {
          onClick: _ctx.switchCollapse,
          class: _normalizeClass({ active: _ctx.isCollapse })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Fold)
          ]),
          _: 1
        }, 8, ["onClick", "class"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.user.name.split('')[_ctx.user.name.length - 1]), 1),
        _createVNode(_component_el_dropdown, null, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_dropdown_item, { onClick: _ctx.toConsole }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_School)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" 返回工作台 ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_el_dropdown_item, { onClick: _ctx.loginOut }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_SwitchButton)
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" 退出登录 ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.user.name) + " ", 1),
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_down)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["main-breadcrumb", { active: _ctx.isCollapse }])
    }, [
      _createVNode(_component_el_breadcrumb, null, {
        default: _withCtx(() => [
          _createVNode(_TransitionGroup, { name: "breadcrumb" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbList, (route, i) => {
                return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                  key: route.name,
                  to: { name: route.name },
                  class: _normalizeClass({ 'is-last-link': i == _ctx.crumbList.length - 1, })
                }, {
                  default: _withCtx(() => [
                    (route.name != 'home')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.meta.title), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 2)
  ], 64))
}