/*
 * @Author: Maria
 * @Description:
 * @Date: 2022-09-19 14:09:42
 * @LastEditTime: 2023-06-11 15:17:14
 * @FilePath: \business\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store/index'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "index" */ '@/components/nav/index.vue')
  },
  {
    path: '/403',
    meta: {
      title: '无权限'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403.vue')
  },
  {
    path: '/404',
    meta: {
      title: '未找到'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404.vue')
  },
  {
    path: '/500',
    meta: {
      title: '服务错误'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500.vue')
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to: any, from: any, next: any) => {
  console.log(to, '009999999999999999999734664')
  // 添加网站标题
  if (to.meta.title && typeof (to.meta.title) === 'string') {
    if (to.path === '/') {
      document.title = '钻转回收系统'
    } else {
      document.title = to.meta.title + '-英奢集团'
    }
  }
  // 顶部面包屑
  const routerList = to.matched
  store.commit('SET_CRUMBLIST', routerList)
  // 当前active导航菜单
  // console.log(to.name, from.name, 'allto')
  if (routerList.length >= 2) {
    console.log(routerList, 'routerList')
    const parent = routerList[routerList.length - 2].children
    parent.forEach((item:any) => {
      if (item.name === to.name) {
        if (!item.hidden) {
          store.commit('SET_CURRENT_MENU', to.name)
        } else {
          store.commit('SET_CURRENT_MENU', from.name)
        }
      }
    })
  } else {
    store.commit('SET_CURRENT_MENU', to.name)
  }
})

export default router
