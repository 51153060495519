
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import { ElLoading } from 'element-plus'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'App',
  setup () {
    const store = useStore()
    const getRouters = computed(() => store.getters.getRouters)
    const router = useRouter()
    router.beforeEach((to, from, next) => {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      if (getRouters.value) {
        loading.close()
        next()
      }
    })

    return {}
  }
})
