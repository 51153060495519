
import { defineComponent, computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'

import { ElMessage } from 'element-plus'
import { getUserInfoApi } from '@/api/api'

export default defineComponent({
  setup () {
    const toConsole = () => {
      window.location.href = process.env.VUE_APP_API_BASE_CONSOLE || ''
    }

    const loginOut = () => {
      // router.go(0)
      window.localStorage.removeItem('token')
      window.location.href = process.env.VUE_APP_API_BASE_LOGIN || ''
    }
    // 获取 DOM 元素
    const domTopRef: any = ref(null)
    // domTopRef.value.style.marginLeft = '200px'
    const store = useStore()
    const users = computed(() => store.state.userList)
    const userInfo = JSON.parse(JSON.stringify(users.value))
    const crumbList = computed(() => store.getters.getCrumbList)
    const isCollapse = computed(() => store.getters.getIsCollapse)
    const switchCollapse = () => {
      store.commit('switchCollapse', !isCollapse.value)
    }

    const user = reactive({
      name: userInfo.userName,
      photoUrl: 'https://img0.baidu.com/it/u=2924490029,2419431244&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200'
    })

    // 获取按钮权限
    // getUserInfoApi().then((res: any) => {
    //   if (res.data.code !== 200) return ElMessage.error(res.data.message)
    //   user.name = res.data.data.userName
    //   store.commit('SET_USERS', res.data.data)
    // })

    return {
      user,
      toConsole,
      loginOut,
      domTopRef,
      crumbList,
      isCollapse,
      switchCollapse
    }
  }
})
